.pageHeading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    /* Black */

    color: #535353;
}
.metaHeading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    /* or 111% */

    text-align: center;

    /* Black */

    color: #535353;
    margin-top: 15px;
}

.pageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;
    flex-grow: 1;
}

.footerButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}
